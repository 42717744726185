import React from 'react';
import art1 from '../../assets/images/limena/artboard1.png';
import art2 from '../../assets/images/limena/artboard2.png';
import art3 from '../../assets/images/limena/artboard3.png';
import art4 from '../../assets/images/limena/artboard4.png';
function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12 col-md-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">NUESTRA EXPERIENCIA, PROFESIONALISMO Y COMPROMISO NOS HA PERMITIDO CRECER Y GANAR LA CONFIANZA DE NUESTRAS MARCAS Y CLIENTES</h3>
                          
                            </div>
                        </div>
              
                    </div>
                    <div className="row pt-50">
                     
                            <div class="col-md-6">
                            <div 
                                            className="appie-features-content wow animated fadeInRight text-center"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                          
                                            <h3 className="title pb-30 pt-50">
                                            COMPETENCIAS
                                            </h3>
                                            <p>
                                            Disponibilidad de +900 productos de +90 marcas de productos étnicos
                                            </p>
                                            <hr className="hrcompetencias"/>
                                            <p>
                                            Catálogo de produtos secos, refrigerados y congelados a su disposición                                            </p>
                                            <p>  <hr className="hrcompetencias"/>
                                            Atención personalizada a sus necesidades                                            </p>
                                            <p>  <hr className="hrcompetencias"/>
                                            
                                            Cobertura disponible en 7 estados de sureste de USA
                                            </p>  <hr className="hrcompetencias"/>
                                           
                                        </div>
                                
                                </div>
                            <div class="col-md-6">
                                <div class="row">
                            <div className="col-lg-6 col-md-6 marginpad">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                  <div className="features-shape-1">
                <img src={art1} alt="" />
            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                            <div className="features-shape-1">
                <img src={art2} alt="" />
            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 marginpad">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                              <div className="features-shape-1">
                <img src={art3} alt="" />
            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                              <div className="features-shape-1">
                <img src={art4} alt="" />
            </div>
                            </div>
                        </div>
                        </div>
                                </div>
                       
                     
                   
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
