import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeSix/FooterHomeSix';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import CounterArea from '../Negocios/CounterArea';
import HeaderService from '../HomeSix/HeaderHomeSix';
import HeroService from './HeroAbout';

function Negocios() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            <HeroService />
            <CounterArea/>
      
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Negocios;
