import React, { useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FeaturesHomeOne from '../HomeOne/FeaturesHomeOne';
import PricingHomeOne from '../HomeOne/PricingHomeOne';
import BlogHomeThree from '../HomeThree/BlogHomeThree';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import CounterArea from '../HomeTwo/CounterArea';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import VideoPlayerHomeTwo from '../HomeTwo/VideoPlayerHomeTwo';
import Drawer from '../Mobile/Drawer';
import FooterHomeSix from './FooterHomeSix';
import HeaderHomeSix from './HeaderHomeSix';
import HeroHomeSix from './HeroHomeSix';
import PopupVideo from '../../components/PopupVideo';

function HomeSix() {
    const [showVideo, setVideoValue] = useState(true);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
          {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSix action={drawerAction.toggle} />
            <HeroHomeSix />
            <DownloadHomeThree className="pt-90 pb-30" />
            <ServicesHomeTwo className="pt-90" />
            <BackToTop className="back-to-top-6" />
            {/* <FeaturesHomeOne className="appie-features-6-area" /> */}
            <CounterArea />
            {/* <VideoPlayerHomeTwo /> */}
           
           {/*  <PricingHomeOne className="appie-pricing-6-area" />
            <BlogHomeThree /> */}
            <FooterHomeSix />
        </>
    );
}

export default HomeSix;
