import React from 'react';
import teamone from '../../assets/images/limena/image1historia.jpeg'
import teamtwo from '../../assets/images/limena/image2historia.jpeg'
import teamthree from '../../assets/images/limena/image3historia.jpeg'

function ServicesAbout() {
    return (
        <>
            <section className="appie-services-2-area pt-30 pb-55" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Historia</h3>
                                <p>
                                Asi comenzamos Distribuidora Limeña, una empresa que nació con la misión de conectar con nuestra herencia latina a traves de su riqueza culinaria; la vision de abrir espacios para productos etnicos.<br/><br/>

Desde el comienzo, sus fundadores y asociados trabajaron concentrados en su vision de darle un giro completo al mercado, convirtiendolo en poco tiempo en un negocio especializado de productos etnicos en el suroreste de Estados Unidos. De esta forma, Distribuidora Limena se consolida en el sector a traves de la generacion de oportunidades rentables y la creacion de empleos directos e indirectos dentro de la poblacion hispana en los Estados Unidos y paises de origen donde la mayoria de los productos son fabricados.
<br/><br/>
Actualmente, en Distribuidora Limeña contamos con mas de +90 colaboradores y estamos enfocados en crear un espacio donde puedan desarrollar su potencial, llevando la cultura latina a niveles de alto profesionalismo.
<br/><br/>
Gracias a nuestro equipo hemos podido identificar necesidades en el mercado, esto nos ha llevado a nuevos emprendimientos y diversificacion del core del negocio.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                                <div className="col-md-4">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamone} alt="" />
                                </div>
                             
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamtwo} alt="" />
                                </div>
                             
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamthree} alt="" />
                                </div>
                             
                            </div>
                        </div>
               
                                </div>

                </div>
            </section>
  
        </>
    );
}

export default ServicesAbout;
