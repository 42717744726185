import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="appie-about-top-title">
                                <p>TODO GRAN PROYECTO, NACE POR</p>
                                <h2 style={{fontSize:50}} className="title">EL SUEÑO DE PERSONAS VISIONARIAS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
