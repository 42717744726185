import React from 'react';
import downloadThumbTwo from '../../assets/images/limena/move03.png';

function DownloadHomeThree({ className }) {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download">
                <div className="container">
                    <div className="row">
                        <img src={downloadThumbTwo} alt="" />

                      
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadHomeThree;
