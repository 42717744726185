import React from 'react';
import banner_marcas from '../../assets/images/limena/marcas.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import partnersbackground from '../../assets/images/limena/partners.png';
import cmlog from '../../assets/images/limena/ritefill_logo.png';
import cmk from '../../assets/images/limena/Guatemaya.png';
import donbeto from '../../assets/images/limena/donbeto.png';
import influencia from '../../assets/images/limena/influencia.png';
import general from '../../assets/images/limena/puntospartners.png';

function CounterArea({ style }) {
    return (
        <>
                    
                    <section className={`appie-download-area pt-60 pb-90 mb-90 ${style || ''}`}>
                <div className="container">
                <div className="row align-items-end">
                        <div className="col-lg-12 col-md-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title" style={{color:'#fff'}}>SOCIOS ESTRATÉGICOS</h3>
                          
                            </div>
                        </div>
              
                    </div>
                    <div className="row containerpartners"> 
                        <div className="col-lg-12 ">
                            <div class="row colpartnersmain" >
                         
                                 {/* <div className="col-md-3"> <img src={influencia} width='320' alt=""  class="imagespartners"/></div> */}
                                 <div className="col-md-4"><img src={cmlog} alt="" class="imagespartners" width={290} /></div>
                                 <div className="col-md-4"><img src={cmk} alt="" class="imagespartners" width={200}/></div>
                                 <div className="col-md-4"><img className='mt-4' src={donbeto} class="imagespartners" width={390} alt="" /></div>
                           
                                    </div>
                                    <div class="row">
                         
                         {/* <div className="col-md-3"> 
                         <img src={general} width='50' alt="" class="generalpoints" />
                         <h6 style={{color:'#fff', textAlign:'center'}}>Influencia Digital</h6><br/>
                         <p style={{color:'#fff', height:'90px', textAlign:'center'}}>Servicios especializados de comunicacion y publicidad digital en el mercado de Estados Unidos.</p>
                         <a className="main-btn btnpartners" target={"_blank"} href="https://idigitalstudios.com/">SABER MÁS</a>
                         </div> */}
                         <div className="col-md-4">
                         <img src={general} width='50' alt="" class="generalpoints" />
                         <h6 style={{color:'#fff', textAlign:'center'}}>Ritefill</h6><br/>
                         <p  style={{color:'#fff', height:'90px', textAlign:'center'}}>Comercialización de productos a través de diferentes canales modernos.</p> 
                         <a className="main-btn btnpartners" target={"_blank"} href="https://ritefill.net/">SABER MÁS</a>
                         </div>
                         
                         <div className="col-md-4"> <img src={general} width='50' alt=""  class="generalpoints"/>
                         <h6 style={{color:'#fff', textAlign:'center'}}>Guatemaya</h6><br/>
                         <p style={{color:'#fff', height:'90px', textAlign:'center'}}>Marca Premium dedicada a la elaboración de productos guatemaltecos.</p> 
                         <a className="main-btn btnpartners" target={"_blank"} href="https://ethnixgroup.com/">SABER MÁS</a>
                         </div>
                       
                         <div className="col-md-4"> <img src={general} width='50' alt="" class="generalpoints"/>
                         <h6 style={{color:'#fff', textAlign:'center'}}>Don Beto</h6><br/>
                         <p style={{color:'#fff', height:'90px', textAlign:'center'}}>Una de nuestras marcas Premium para la elaboracion de productos etnicos.</p>
                         <a className="main-btn btnpartners" target={"_blank"} href="https://www.donbetousa.com/">SABER MÁS</a>
                          </div>
                  
                   
                            </div>
                                 
                        </div>
                    </div>
                </div>
      
            </section>
            <section className="appie-counter-area pt-10 pb-100" id="counter" style={style}>
                <div className="container">
                <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">NUESTRAS MARCAS</h3>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row">
                     <div clsss="col-lg-12">
                     <img src={banner_marcas} alt="" />
                         </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CounterArea;
