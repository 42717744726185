import React from 'react';
import { Link } from 'react-router-dom';

function FooterHomeSix() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-6">
                                <div className="logo">
                                    <a href="#">
                                        <img src="assets/images/logo-8.png" alt="" />
                                    </a>
                                </div>
                                <p>
                                Si necesita mas informacion sobre cualquiera de nuestros servicios, contactenos a traves del
                                </p>
                                <a href="tel:+1 (615) 832-0906">
                                    Servicio al cliente <i className="fal fa-phone"></i>
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/DistribuidoraLimena/">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/distribuidoralimena/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/distribuidora-limena-inc">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-6">
                                <h4 className="title">Distribuidora Limena Inc</h4>
                                <ul>
                                    <li>
                                        <Link to="/quienessomos">Quienes Somos</Link>
                                    </li>
                                    <li>
                                        <Link to="/historia">Historia</Link>
                                    </li>
                                    <li>
                                   
                                        <Link to="/socios">Nuestros Socios</Link>
                                    </li>
                                    <li>
                                        <Link to="/marcas">Nuestras Marcas</Link>
                                    </li>
                                    <li>
                                        <a href="https://apply.limenainc.net/">Oportunidades</a>
                                    </li>
                                    <li>
                                        <Link to="/contacto">Contacto</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation footer-navigation-6">
                                <h4 className="title">Plataforma</h4>
                                <ul>
                                    <li>
                                        <a href="https://app.limenainc.net/">Iniciar sesion</a>
                                    </li>
                                    <li>
                                        <a href="https://app.limenainc.net/">Registrarse</a>
                                    </li>
                                    {/* <li>
                                        <a href="#">Faqs</a>
                                    </li>
                                    <li>
                                        <a href="#">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="#">Careers</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Mantente en contacto</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope"></i> customercare@limenainc.net
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+1 (615) 832-0906">
                                            <i className="fal fa-phone"></i> +1 615-832-0906
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://goo.gl/maps/sQVRrzj3xNJMz3q36">
                                            <i className="fal fa-map-marker-alt"></i> 200 Threet Industrial Road Suite 110 Smyrna, TN 37161
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                {/* <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple"></i> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play"></i> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="copyright-text">
                                    <p>Copyright © Distribuidora Limena Inc 2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeSix;
