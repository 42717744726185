import React from 'react';

function HeroHomeSix() {
    return (
        <>
            <section className="appie-hero-area appie-hero-6-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-7">
                            <div className="appie-hero-content appie-hero-content-6" style={{height:400}}>
                           
                                <h1 className="titleslider">SOMOS LIDERES EN LA DISTRIBUCIÓN Y COMERCIALIZACIÓN DE PRODUCTOS ÉTNICOS</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeSix;
