import React from 'react';
import banner_marcas from '../../assets/images/limena/marcas.png';
function HeroAbout(style) {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="appie-about-top-title">
                                <p>MEJORES PRODUCTOS</p>
                                <h2 style={{fontSize:50}} className="title">MEJORES GANANCIAS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-contentmarcas">
                           
                              
                            </div>
                        </div>
                        <div className="appie-about-top-title text-center" style={{marginTop:-50, marginBottom:100}}>
                                <h1>Diversifique su cartera de productos para aumentar sus ganancias
</h1>
                           
                            </div>
                    </div>
                </div>
            </section>

            <section className="appie-counter-area pt-10 pb-100" id="counter" style={style}>
                <div className="container">
                <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">NUESTRAS MARCAS</h3>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row">
                     <div clsss="col-lg-12">
                     <img src={banner_marcas} alt="" />
                         </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
