import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/limena/logolimena-ethnix.png';

function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                    <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/quienessomos">Quienes Somos</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/historia">Historia</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/socios">Nuestros Socios</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/marcas">Nuestras Marcas</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'service')}
                                            id="service"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="https://apply.limenainc.net/">Oportunidades</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contacto">Contacto</Link>
                                        </li>
                                        
                                    </ul>
                                </div>
                                
               
               
               <a className="login-btn main-btn btnlg" href="https://app.limenainc.net/" style={{backgroundColor:'#fee702', borderColor:'#fee702', color:"#000", marginBottom:20, marginTop:10}}>
                                       <i className="fal fa-user"></i> INICIAR SESIÓN
                                   </a>
            
               <a className="login-btn main-btn btnlg" href="https://enroll.limenainc.net/" style={{backgroundColor:'#fee702', borderColor:'#fee702', color:"#000"}}>
                                       <i className="fal fa-user-plus"></i> REGISTRO CLIENTE
                                   </a>
               
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href="https://www.facebook.com/DistribuidoraLimena/">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                  
                                        <li>
                                            <a href="https://www.instagram.com/distribuidoralimena/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/distribuidora-limena-inc">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i>{' '}
                                                customercare@limenainc.net                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+1 (615) 832-0906">
                                                <i className="fal fa-phone"></i> +1 615-832-0906
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i> 1188 Antioch Pike, Nashville, TN 37211, Estados Unidos
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
