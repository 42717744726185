import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="appie-about-top-title">
                                <p>NUESTRA UNION HACE</p>
                                <h2 style={{fontSize:50}} className="title">UNA GRAN FUERZA</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-contentsocios">
                           
                              
                            </div>
                        </div>
                        <div className="appie-about-top-title text-center" style={{marginTop:-50, marginBottom:100}}>
                            <h1>Nuestros socios estrategicos nos ayudan a brindarle un servicio más allá de sus expectativas</h1>
                           
                            </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
