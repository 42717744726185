import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
            <li>
                    <Link to="/quienessomos">QUIENES SOMOS</Link>
                </li>
                <li>
                    <Link to="/historia">HISTORIA</Link>
                </li>
                <li>
                    <Link to="/socios">NUESTROS SOCIOS</Link>
                </li>
                <li>
                    <Link to="/marcas">NUESTRAS MARCAS</Link>
                </li>
                <li>
                    <a href="https://apply.limenainc.net/">OPORTUNIDADES</a>
                </li>
                <li>
                    <Link to="/contacto">CONTACTO</Link>
                </li>

  
            
                <li>
               
                <a className="login-btn main-btn btnlg" href="https://app.limenainc.net/">
                                        <i className="fal fa-user"></i> INICIAR SESIÓN
                                    </a>
                </li>
                <li>
                <a className="login-btn main-btn btnlg" href="https://enroll.limenainc.net/">
                                        <i className="fal fa-user-plus"></i> REGISTRO CLIENTE
                                    </a>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
