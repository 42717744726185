import React from 'react';
import emailjs from '@emailjs/browser';
function Forms() {

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_b1a0hiw', 'template_83qlsla', e.target, 'user_4dM4z33kE4xbfbOAnHUdl')
          .then((result) => {
              console.log(result.text);
              alert("Your message was successfully sent!")
              window.location.reload(false); 
          }, (error) => {
              console.log(error.text);
          });
      }

    return (
        <>
            <section className="contact-section" style={{marginTop:100}}>
                <div className="container">
                    <div className="row">
                    
                        <div className="col-md-12">
                            <div className="contact-form">
                                <h4>Contáctanos</h4>
                                <p>Aumente la rentabilidad de su empresa con servicios adecuados a su necesidad.</p>
                                <form className="row" onSubmit={sendEmail}>
                                    <div className="col-md-6">
                                        <input type="text" name="from_name" placeholder="Nombre completo" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="from_company" placeholder="Empresa"  required/>
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="from_email"
                                            placeholder="Email Address"
                                            required
                                        />
                                    </div>
                          
                                
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="¿Cómo podemos ayudarte?"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                      
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Enviar" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://maps.google.com/maps?q=Distribuidora%20Limena&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                ></iframe>
            </div>
        </>
    );
}

export default Forms;
